/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { PreviewType } from 'embercom/components/articles/site/settings/react-preview';

export default class CollectionsController extends Controller {
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare router: RouterService;
  containerReference: any;

  @tracked createNewCollectionAction: any;

  // Code to get the preview appearing alongside collections
  get previewType() {
    return PreviewType.Homepage;
  }

  // Code to get the preview appearing alongside collections
  @action setPreviewType(_type: $TSFixMe) {}

  // Code to get the preview appearing alongside collections
  get uploadHelper() {
    return this.model.uploadHelper;
  }

  get groupList() {
    let items = this.helpCenterService.site.selectedLocalesOrdered.map(
      (locale: $TSFixMe) => locale.data,
    );

    items.forEach((locale: $TSFixMe) => {
      locale.text = locale.name;
    });

    return [{ items }];
  }

  @action
  updateLocale(locale: $TSFixMe) {
    this.helpCenterService.updateLocale(locale.localeId);
  }

  declare model: {
    allCollections: any;
    allArticlesSummaries: any;
    uploadHelper: any;
  };

  queryParams = ['collectionId'];

  @tracked collectionId: string | undefined;

  @action
  getContainerRef() {
    return this.containerReference;
  }

  get app() {
    return this.appService.app;
  }

  get helpCenterSite() {
    return this.helpCenterService.site;
  }

  get collectionsWithoutHome() {
    return this.allCollections.rejectBy('isHome', true);
  }

  get collections() {
    return this.collectionsWithoutHome.sortBy('order');
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get defaultLocaleId() {
    return this.helpCenterSite.locale;
  }

  get selectedLocaleName() {
    let localeCount = '';
    if (this.helpCenterService.site.selectedLocalesOrdered.length > 1) {
      localeCount = ` (${this.helpCenterService.site.selectedLocalesOrdered.length})`;
    }
    return `${this.helpCenterService.getLocaleNameFromLocaleCode(this.selectedLocaleId)}${localeCount}`;
  }

  get allCollections() {
    return this.model.allCollections;
  }

  get allArticlesSummaries() {
    return this.model.allArticlesSummaries;
  }

  get selectedCollection() {
    if (!this.collectionId) {
      return null;
    }

    return this.collectionsWithoutHome.findBy('id', this.collectionId);
  }

  @action
  goToAllArticles() {
    let url = this.router.urlFor('apps.app.articles');
    safeWindowOpen(url, '_blank');
  }

  @action
  createNewCollection() {
    if (this.createNewCollectionAction) {
      this.createNewCollectionAction();
    }
  }
}
